import styles from './style.module.css';
import { useState } from 'react';

import Letter from '../../icons/letter.svg';
import Tel from '../../icons/tel.svg';
import Location from '../../icons/location.svg';

const Form = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const handleNameChange = (event) => {

    // Validation for error message name ===============================================
    setName(event.target.value);
    let errorMsgName = "";
    if (!event.target.value.trim()) {
      errorMsgName = "Required field.";
    }
    if (/[^a-zA-Z\s]/.test(event.target.value)) {
      errorMsgName = 'Invalid name format. Please use letters only';
    }
    setNameError(
      errorMsgName
    );
  };

  const handleEmailChange = (event) => {

    // Validation for error message email =================================================
    setEmail(event.target.value);
    let errorMsgEmail = '';

    if (!/\S+@\S+\.\S+/.test(event.target.value)) {
      errorMsgEmail = 'Please enter a valid email address'
      if (!event.target.value.trim()) {
        errorMsgEmail = "Required field.";
      }
    }
    setEmailError(
      errorMsgEmail
    );
  };


  const handlePhoneChange = (event) => {

    // Validation for error message email =============================================
    setPhone(event.target.value);
    let errorMsgPhone = ''
    if (!event.target.value.trim()) {
      errorMsgPhone = "Required field.";
    }
    if (!/^(\+)?\d+$/.test(event.target.value)) {
      errorMsgPhone = 'Please enter a valid phone number'
    }
    setPhoneError(
      errorMsgPhone
    );
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };
  const handleSubmit = async () => {
    let valid = true;

    // Validation for error message name ==================================================
    if (!name.trim() || /[^a-zA-Z\s]/g.test(name)) {
      let errorMsgName = ''
      if (!name.trim()) {
        errorMsgName = 'Required field.'
      }
      if (/[^a-zA-Z\s]/g.test(name)) {
        errorMsgName = 'Invalid name format. Please use letters only';
      }
      setNameError(errorMsgName);
      valid = false;
    }

    // Validation for error message email ===================================================
    if (!email.trim() || !/\S+@\S+\.\S+/.test(email)) {
      let errorMsgEmail = '';

      if (!/\S+@\S+\.\S+/.test(email)) {
        errorMsgEmail = 'Please enter a valid email address'
        if (!email.trim()) {
          errorMsgEmail = 'Required field.'
        }
      }
      setEmailError(errorMsgEmail);
      valid = false;
    }

    // Validation for error message phone ===================================================
    if (!phone.trim() || !/^(\+)?\d+$/.test(phone)) {
      let errorMsgPhone = '';
      if (!phone.trim()) {
        errorMsgPhone = 'Required field.';
      }
      if (!/^(\+)?\d+$/.test(phone) ) {
        errorMsgPhone = 'Please enter a valid phone number'
      }
      setPhoneError(errorMsgPhone);
      valid = false;
    }

    if (valid) {
      console.log("test");
      const url = 'https://worxback.worx.am/sendMail';
      const data = {
        name: name,
        phone: phone,
        email: email,
        message: message,
      };
      const params = new URLSearchParams();
      for (const [key, value] of Object.entries(data)) {
        params.append(key, value);
      }
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: params.toString(),
        });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const responseData = await response.json();
        console.log(responseData);
        alert("Your message was sent!");
        setName('');
        setPhone('');
        setEmail('');
        setMessage('');
      } catch (error) {
        console.error('Error:', error.message);
      }
    }
  }

  return (
    <section style={{
      width: '100%'
    }}>
      <div className={styles.content} id='Form-Content'>
        <div className={styles.inner} >
          <div className={styles.left_side}>
            <h2 className={styles.title}>
              Perfect Solutions<br /> For Your Business
            </h2>
            <p className={styles.descr}>Where your vision meets our innovation.</p>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '15px'
            }}>
              <div className={styles.info}>
                <img src={Letter} alt="" />
                {/* <span>info@worx.am</span> */}
                <a className={styles.form_link} href="mailto:info@worx.am">info@worx.am</a>
              </div>
              <div className={styles.info}>
                <img src={Tel} alt="" />
                {/* <span>+374 41 96  96 20</span> */}
                <a className={styles.form_link} href="tel:+374 41 96  96 20">+374 41 96  96 20</a>
              </div>
              <div className={styles.info}>
                <img src={Location} alt="" />
                <span>Armenia, Yerevan Fuchik str.</span>
              </div>
            </div>
          </div>
          <div className={styles.right_side}>
            <form className={styles.form} method="post">
              <input type="text" placeholder='Name' name='name' value={name} required onChange={handleNameChange} />
              {nameError && <span className="error_message">{nameError}</span>}
              <input type="email" placeholder='E-mail' name='email' value={email} required onChange={handleEmailChange} />
              {emailError && <span className="error_message">{emailError}</span>}
              <input type="text" placeholder='Phone number' name='phone' value={phone} required onChange={handlePhoneChange} />
              {phoneError && <span className="error_message">{phoneError}</span>}
              <textarea placeholder='Your text...' style={{ marginBottom: '30px' }} name='message' value={message} onChange={handleMessageChange} />
              <div style={{ position: 'relative', height: '50px' }}>
                <button className={styles.submit_btn} type='button' onClick={handleSubmit}>
                  <span>Submit request</span>
                </button>
                <div className={styles.shadow}></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>

  )
}

export default Form
